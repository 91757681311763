<div class="dialog-container fixed inset-0 z-40" role="dialog" aria-modal="true" [class.visible]="open$ | async">
    <div
        class="overlay fixed inset-0 bg-black bg-opacity-25"
        (click)="close.emit()"
        *ngIf="expanded"
        [attr.aria-hidden]="!expanded"
    ></div>
    <div
        class="tray ml-auto relative w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-hidden"
        [ngClass]="trayClasses"
        [class.expanded]="expanded"
    >
        <div class="px-4 flex items-center justify-between">
            <h2 class="text-lg font-medium text-gray-900">{{ title }}</h2>
            <button
                type="button"
                (click)="close.emit()"
                class="-mr-2 w-10 h-10 p-2 flex items-center justify-center text-gray-400 hover:text-gray-500"
            >
                <span class="sr-only">Close menu</span>
                <!-- Heroicon name: outline/x -->
                <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>
        </div>
        <div class="overflow-y-auto">
            <ng-content></ng-content>
        </div>
    </div>
</div>
