import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { interval, Observable, Subject } from "rxjs";
import { delayWhen } from "rxjs/operators";
import { NgIf, NgClass, AsyncPipe } from "@angular/common";

@Component({
    selector: 'kb-side-tray',
    templateUrl: './side-tray.component.html',
    styleUrls: ['./side-tray.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        AsyncPipe,
    ],
})
export class SideTrayComponent implements OnInit, OnChanges {
    @Input() trayClasses = '';
    @Input() expanded = false;
    @Input() title = '';
    @Output() close = new EventEmitter();

    open$: Observable<boolean>;
    private _open$ = new Subject<boolean>();

    ngOnInit() {
        this.open$ = this._open$.pipe(delayWhen((isOpen) => interval(isOpen ? 0 : 300)));
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('expanded' in changes) {
            this._open$.next(changes['expanded'].currentValue);
        }
    }
}
